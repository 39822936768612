import React, { useState } from "react"
import { PlusSmIcon } from '@heroicons/react/solid'

// draws a community's star rating and count
const StarRating = ({
  rating,
  starHeight, // defaults to 5
}) => {

  const totalStars = parseInt(rating);
  const hasHalfStar = (parseFloat(rating) - totalStars) >= 0.5;
  const totalEmptyStars = 5 - parseInt(rating) - (hasHalfStar ? 1 : 0);

  return (
    <div className="flex items-center">
      {[1,2,3,4,5].filter(i => i <= totalStars).map((s, index) => (
        <svg key={s} xmlns="http://www.w3.org/2000/svg" className={`h-${starHeight || '5'} w-${starHeight || '5'} text-yellow-400`} fill="#fef3c7" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
      ))}
      {hasHalfStar ? (
        <div className={`relative h-${starHeight || '5'} w-${starHeight || '5'}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className={`absolute h-${starHeight || '5'} w-${starHeight || '5'} opacity-20`} fill="#fff" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className={`absolute h-${starHeight || '5'} w-${starHeight || '5'} text-yellow-400`}  viewBox="0 0 20 20" fill="#fff" stroke="currentColor">
            <path d="M 9.049,2.927 C 9.1985626,2.4678429 9.5974148,2.2375949 9.9968701,2.2362559 l -5.9e-6,11.8199721 C 9.7915851,14.056767 9.5864369,14.120358 9.412,14.247 l -2.8,2.034 C 5.828,16.851 4.774,16.084 5.073,15.163 l 1.07,-3.292 C 6.276704,11.45883 6.1297413,11.007444 5.779,10.753 L 2.98,8.72 C 2.197,8.15 2.6,6.91 3.568,6.91 H 7.029 C 7.4619424,6.9101141 7.8457879,6.6316142 7.98,6.22 L 9.05,2.928 Z" />
          </svg>
        </div>
      ) : ''}
      {[1,2,3,4,5].filter(i => i <= totalEmptyStars).map((s, index) => (
        <svg key={s} xmlns="http://www.w3.org/2000/svg" className={`h-${starHeight || '5'} w-${starHeight || '5'} opacity-20`} fill="#fff" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
        </svg>
      ))}
    </div>
  )

}


export default StarRating
